import React from 'react';
import { arrayOf, string, shape, bool, number } from 'prop-types';

import { PolycardProvider } from '../../../polycard/componentes/provider';
import { ItemResult } from '../item-result';
import { CountryPropTypes } from '../../constants/country.prop-types';
import { PolyCardContextPropTypes } from '../../../polycard/prop-types/polycard-context.prop-types';
import { ResultPropTypes } from '../../constants/result.prop-types';
import { layoutNamespace } from '../../constants';
import { withComponentHandler } from '../../../../hocs/with-component-handler';

/**
 * @typedef {Object} GridColumnProps
 * @property {string} [className]
 * @property {number} [cols]
 * @property {CountryPropTypes} country
 * @property {string} deviceType
 * @property {boolean} [isPolycard]
 * @property {boolean} [isShops]
 * @property {string} layout
 * @property {Object} [options]
 * @property {boolean} [options.lowEnd]
 * @property {boolean} [options.verboseLabels]
 * @property {boolean} [options.cardSupportsLinks]
 * @property {PolyCardContextPropTypes} [polycard_context]
 * @property {Array<ResultPropTypes>} [results]
 * @property {string} [vertical]
 */

/**
 * GridColumn component.
 *
 * @param {GridColumnProps} props
 * @returns {JSX.Element}
 */
const GridColumnComponent = ({
  country,
  deviceType,
  layout,
  polycard_context = null,
  results = [],
  className,
  cols,
  options,
  isPolycard,
  vertical,
  isShops,
}) => (
  <PolycardProvider contextValue={polycard_context} layout={layout} deviceType={deviceType} countryConfig={country}>
    <ol className={className} data-cols={cols}>
      {results.map((item, index) => (
        <ItemResult
          // eslint-disable-next-line react/no-array-index-key
          key={`${item.id}-${index}`}
          item={item}
          index={index}
          deviceType={deviceType}
          options={options}
          namespace={layoutNamespace}
          layout={layout}
          isPolycard={isPolycard}
          vertical={vertical}
          isShops={isShops}
        />
      ))}
    </ol>
  </PolycardProvider>
);

GridColumnComponent.propTypes = {
  className: string,
  cols: number,
  country: CountryPropTypes,
  deviceType: string,
  isPolycard: bool,
  isShops: bool,
  layout: string.isRequired,
  options: shape({ lowEnd: bool, verboseLabels: bool, cardSupportsLinks: bool }),
  polycard_context: PolyCardContextPropTypes,
  results: arrayOf(ResultPropTypes),
  vertical: string,
};

export const GridColumnLayout = withComponentHandler(GridColumnComponent, {
  componentName: 'GridColumnLayout',
});
